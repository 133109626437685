import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-92d8ba44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row mb-10" }
const _hoisted_2 = { class: "col span-4" }
const _hoisted_3 = { class: "row location-row mb-10" }
const _hoisted_4 = { class: "col span-4" }
const _hoisted_5 = {
  key: 0,
  class: "col span-3 extra-zones",
  "data-testid": "gke-extra-zones-container"
}
const _hoisted_6 = { class: "text-muted" }
const _hoisted_7 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "row mt-20 mb-10" }
const _hoisted_10 = { class: "col span-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_KeyValue = _resolveComponent("KeyValue")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_LabeledSelect, {
          options: _ctx.versionOptions,
          "label-key": "gke.version.label",
          value: _ctx.kubernetesVersion,
          tooltip: _ctx.isCreate? '' :_ctx.t('gke.version.tooltip'),
          loading: _ctx.loadingVersions,
          "data-testid": "gke-version-select",
          mode: _ctx.mode,
          onSelecting: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:kubernetesVersion', $event.value)))
        }, null, 8, ["options", "value", "tooltip", "loading", "mode"])
      ])
    ]),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.useRegion)
          ? (_openBlock(), _createBlock(_component_LabeledSelect, {
              key: 0,
              "label-key": "gke.location.region",
              mode: _ctx.mode,
              options: _ctx.regions,
              value: _ctx.region,
              disabled: !_ctx.isNewOrUnprovisioned,
              loading: _ctx.loadingZones,
              onSelecting: _ctx.setRegion
            }, null, 8, ["mode", "options", "value", "disabled", "loading", "onSelecting"]))
          : (_openBlock(), _createBlock(_component_LabeledSelect, {
              key: 1,
              "label-key": "gke.location.zone",
              mode: _ctx.mode,
              options: _ctx.zones,
              "option-key": "name",
              "option-label": "name",
              value: _ctx.zone,
              disabled: !_ctx.isNewOrUnprovisioned,
              loading: _ctx.loadingZones,
              onSelecting: _ctx.setZone
            }, null, 8, ["mode", "options", "value", "disabled", "loading", "onSelecting"]))
      ]),
      _createTextVNode(),
      (!_ctx.loadingZones)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('gke.location.extraZones')), 1),
            _createTextVNode(),
            (_ctx.isView && !_ctx.locations.length)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, "—"))
              : _createCommentVNode("", true),
            _createTextVNode(),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extraZoneOptions, (zoneOpt, i) => {
              return (_openBlock(), _createBlock(_component_Checkbox, {
                key: i,
                label: zoneOpt.name,
                value: _ctx.locations.includes(zoneOpt.name),
                "data-testid": `gke-extra-zones-${zoneOpt.name}`,
                disabled: !_ctx.isNewOrUnprovisioned,
                class: "extra-zone-checkbox",
                "onUpdate:value": e=>_ctx.setExtraZone(e, zoneOpt.name)
              }, null, 8, ["label", "value", "data-testid", "disabled", "onUpdate:value"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createTextVNode(),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_RadioGroup, {
          value: _ctx.useRegion,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.useRegion) = $event)),
          mode: _ctx.mode,
          options: _ctx.zoneRadioOptions,
          name: "regionmode",
          disabled: !_ctx.isNewOrUnprovisioned,
          "data-testid": "gke-location-mode-radio"
        }, null, 8, ["value", "mode", "options", "disabled"])
      ])
    ]),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_KeyValue, {
          mode: _ctx.mode,
          value: _ctx.labels,
          "as-map": true,
          title: _ctx.t('gke.clusterLabels.label'),
          "add-label": _ctx.t('gke.clusterLabels.add'),
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:labels', $event)))
        }, {
          title: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.t('gke.clusterLabels.label')), 1)
          ]),
          _: 1
        }, 8, ["mode", "value", "title", "add-label"])
      ])
    ])
  ]))
}