import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {'justify-content':'space-between'},
  class: "row mb-10"
}
const _hoisted_2 = { class: "col span-2" }
const _hoisted_3 = { class: "col span-2" }
const _hoisted_4 = { class: "col span-2" }
const _hoisted_5 = { class: "col span-2" }
const _hoisted_6 = { class: "col span-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Checkbox, {
          value: _ctx.typeEnabled('audit'),
          mode: _ctx.mode,
          "label-key": "eks.audit.label",
          tooltip: _ctx.t('eks.audit.tooltip'),
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleType('audit')))
        }, null, 8, ["value", "mode", "tooltip"])
      ]),
      _createTextVNode(),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Checkbox, {
          value: _ctx.typeEnabled('api'),
          mode: _ctx.mode,
          "label-key": "eks.api.label",
          tooltip: _ctx.t('eks.api.tooltip'),
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleType('api')))
        }, null, 8, ["value", "mode", "tooltip"])
      ]),
      _createTextVNode(),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Checkbox, {
          value: _ctx.typeEnabled('scheduler'),
          mode: _ctx.mode,
          "label-key": "eks.scheduler.label",
          tooltip: _ctx.t('eks.scheduler.tooltip'),
          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleType('scheduler')))
        }, null, 8, ["value", "mode", "tooltip"])
      ]),
      _createTextVNode(),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Checkbox, {
          value: _ctx.typeEnabled('controllerManager'),
          mode: _ctx.mode,
          "label-key": "eks.controllerManager.label",
          tooltip: _ctx.t('eks.controllerManager.tooltip'),
          onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleType('controllerManager')))
        }, null, 8, ["value", "mode", "tooltip"])
      ]),
      _createTextVNode(),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Checkbox, {
          value: _ctx.typeEnabled('authenticator'),
          mode: _ctx.mode,
          "label-key": "eks.authenticator.label",
          tooltip: _ctx.t('eks.authenticator.tooltip'),
          onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleType('authenticator')))
        }, null, 8, ["value", "mode", "tooltip"])
      ])
    ])
  ]))
}